@import url('https://webfontworld.github.io/seoulhangang/SeoulHangang.css');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@import url('loading.css');

@font-face {
  font-family: 'CookieRun-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/CookieRun-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

*{
  padding:0;
  margin: 0;
  /*font-family: 'SeoulHangang','Montserrat', 'CookieRun-Regular',sans-serif;*/
}

button{
  border: none;
  cursor: pointer;
}
